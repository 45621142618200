.centered {
  margin-top: 5vhwww;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bg-image {
  background-color: whitesmoke;
  height: 100%;
  background-position: center;
}

html,
body,
#root {
  height: 100%;
}

#card-image {
  width: 190px;
  height: 90px;
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 100;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
